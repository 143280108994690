import React,{useEffect,useState} from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";

function ProcessPayment() {

  const [searchParams] = useSearchParams();
  const history = useNavigate();

  useEffect(() => {                                     
    if (searchParams) {
      const sendPaymentProcess = async () => {
        return await axios.post(
          config.url + "payments/process?"+searchParams.toString(),
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
      }; 

      sendPaymentProcess()
      .then((result) => {
   
           if(result.status == 200){ 
            history('/thank-you');
           }
        // if(result.status === 200 && result.data.data.payment_form_url){
        //   window.location.replace(result.data.data.payment_form_url);
        // }
      })
      .catch((error) => {
        console.log(error);
        // if (error.response.status === 401) {
        //   setUser({ loggedIn: false });
        // }
      });

    }

  },[])

  return (
    <div></div>
  )
}

export default ProcessPayment