import React, { useState, useEffect, useContext } from "react";
import "./Profile.css";
import profileGirl from "../images/myphotos/profilegirl.png";

import loadingGif from "../images/myphotos/loading2.gif";
import { FaBirthdayCake, FaVenusMars, FaRegBuilding } from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { AiFillLike, AiFillDollarCircle,AiFillLock } from "react-icons/ai";
import Account from "../Components/Profile/Profile";
import UserDetails from "../Components/User/UserDetails";
import { UserContext } from "../App";
import config from "../config";
import axios from "axios";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

import ImageModal from "../Components/ImageModal/ImageModal";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";


function Profile() {
  // const token = localStorage.getItem('token') ? localStorage.getItem('token') : false;
  const { t, i18n, ready } = useTranslation();
  const [user, setUser] = useState();
  const [likes,setLikes] = useState();
  const [displayModalPhoto, setDisplayModalPhoto] = useState();
  const [modalPhotoSrc, setModalPhotoSrc] = useState();
  useEffect(() => {
    getProfileDetails();
    getProfileLikes();
  }, []);

  const getProfileDetails = async () => {
    const response = await axios.get(config.url + "users/id/me", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    setUser(response.data.user);
  };

  const getProfileLikes = async () => {
    const response = await axios.get(config.url + "likes", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    setLikes(response.data.likes);
  };

  function unblockPhoto(e){

  }

  const Photo = ({ id, photo }) => {
    return (
      <div className="member-details-img img-background pos-rel">
        <img id={"id" + photo.id} src={photo.src} alt="profile-member-img" onClick={handleImageClick}/>
      </div>
    );
  };

  const setStateModal = async (childrenData) => {
    if(childrenData){
      setDisplayModalPhoto(false);
    }
  }

  
  function handleImageClick(e){
    setDisplayModalPhoto(true);
    if(e.target.getAttribute("src")){
      setModalPhotoSrc(e.target.getAttribute("src"));
    }
  }

  return (
    <section className="profile-section">
        <Breadcrumb pageTitle={t("Dashboard")} text = {t("Enjoy the journey of creating a profile that truly represents you")}/>

      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 left-side">
            <Account />
          </div>
          <div className="col-md-9 mb-3 left-side">
            <div className="profile-header main-shadow-box bb-color">
              <div className="profile-header-details">
                <div className="photo-box">
                  <img
                    src={user?.main_pic ? user.main_pic : loadingGif}
                    className="profile-header-photo img-background"
                    alt="profile-img"
                  />
                </div>
                <div className="profile-user-details">
                  <span className="bld">
                    {" "}
                    {user?.username ? user.username : ""}
                  </span>
                  <span>{user?.membership ? user.membership : ""}</span>
                </div>
              </div>

              <div className="profile-buttons">
                <Link className="a-none" to="/membership-credits">
                  {" "}
                  <div className="main-btn tb-color f-white howver">
                    {t("Upgrade membership")}{" "}
                  </div>
                </Link>
                <div className="main-btn pb-color t-color">
                  {" "}
                  <span>
                    {" "}
                    <AiFillDollarCircle className="icon-center-header" />{" "}
                    {user?.credits ? user.credits : "0"} {t("Credits")}{" "}
                  </span>
                </div>
              </div>
            </div>
            <UserDetails user={user} isForm={false} />

          {(user?.pictures &&
                user.pictures.length > 0) && 
                <div className="user-details member-img-all">

                <h3>{t("Photos")}</h3>
  
                {user?.pictures &&
                  user.pictures.length > 0 &&
                  user.pictures.map((photo, index) => {
                    return <Photo key={index} id={photo.id} photo={photo} />;
                  })}
              </div> }



          {
            (user?.gifts && user.gifts.length > 0 &&  <div className="user-details" style={{justifyContent: 'left'}}>
               <h3>{t("Received gifts")}</h3>
               {
                  user.gifts.map((gift, index) => {
                  return <div key={"gift" + index} className="each-user each-received-gift">
                    <div className="featured-users-each-image">
                      <img className="bb-color pad10 main-round-image" src={gift.picture}></img>
                    </div>
                    <div className="mg-left-10">{t("You received a gift from")} <strong>{gift.sender}</strong></div></div>
                  })}
            </div>)
          }

          {
            (likes && likes.length > 0 &&  <div className="user-details" style={{justifyContent: 'left'}}>
               <h3 style={{marginTop:"10px"}}>{t("Received likes")}</h3>
               {
                  likes.map((like, index) => {
                  return <div key={"like" + like.user_id} className="each-received-gift">
                    <div className="featured-users-each-image">
                      {(() => {
                        if(like.picture)
                          return (<img className="br-radius50" src={like.picture}></img>)
                        else
                          return <AiFillLike title="Already liked" className="received-like tb-color p-color"/>
                      })()}
               
                    </div>
                    <div className="mg-left-10">
                      <div className="">
                        <strong><Link className="a-none-2" to={"/profile/" + like.user_id }>{like.username}</Link></strong> {t("liked your profile!")}
                        <br/>
                        <span>{like.created_at}</span>
                      </div>
                      </div>
                    </div>
                  })}
            </div>)
          }

          </div>
        </div>

        {displayModalPhoto && modalPhotoSrc && (
        <ImageModal
          src={modalPhotoSrc}
          close={setStateModal}
        />
      )}
        <div className="left-side"></div>
      </div>
    </section>
  );
}

export default Profile;
