module.exports = {
    // url: 'http://localhost:8000/api/',
    // url: "https://api.luvbinder.com/api/",
    url: process.env.REACT_APP_API_URL,
    appName: "ClickOnFlirt",
    allGenders: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
        { label: "Others", value: "others" },
],
    allCivilsStatus: [
        { label: "Single", value: "single" },
        { label: "Divorced", value: "divorced" },
        { label: "Married", value: "married" },
    ],
    allAges: [
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
    ],
};
