import React from "react";
import "./Profile.css";
import Account from "../Components/Profile/Profile";
import CardMembers from "../Components/ProfileCardsMembers/ProfileCardsMembers";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

function Members() {
    const { t, i18n, ready } = useTranslation();
    return (
        <section className="profile-section">
            <Breadcrumb pageTitle={t("Members")} text = {t("Step out of your comfort zone and discover the magic of meeting new people")} />

            <div className="container">
                <div className="row">
                    <div className="col-md-3 mb-3 left-side">
                        <Account />
                    </div>
                    <div className="col-md-9 mb-3 left-side">
                        <CardMembers />
                    </div>
                </div>
                <div className="left-side"></div>
            </div>
        </section>
    );
}

export default Members;
