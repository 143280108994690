import React, { useState, useEffect } from "react";
import profileGirl from "../../images/myphotos/profilegirl.png";
import {
  FaBirthdayCake,
  FaVenusMars,
  FaRegBuilding,
  FaCircle,
} from "react-icons/fa";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { BsGlobe } from "react-icons/bs";
import { AiFillHeart, AiFillDollarCircle } from "react-icons/ai";
import config from "../../config";
import axios from "axios";
import loadingGif from "../../images/myphotos/loading2.gif";
import Loader from "../Loader/Loader";
import {useTranslation } from 'react-i18next';

function Profile(props) {
  const [user, setUser] = useState();
  const [featuredUsers, setFeaturedUsers] = useState();
  const { t, i18n, ready } = useTranslation();

  const getFeaturedUsers = async () => {
    const response = await axios.get(config.url + "users/featured", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if(response.data.featured_users){
      setFeaturedUsers(response.data.featured_users);
    }

  };

  useEffect(() =>{
    getFeaturedUsers();
  },[])

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    } else {
      getProfileDetails();
    }
  }, []);

  const getProfileDetails = async () => {
    const response = await axios.get(config.url + "users/id/me", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    setUser(response.data.user);
  };


  return (
    <>
      {/* <Loader/> */}
    
      <div className="profile-my-account bb-color main-shadow-box">
        <div className="profile-details">
          <img
            src={user?.main_pic ? user.main_pic : loadingGif}
            className="profile-photo img-background"
            alt="account-img"
          />
          <span>
            {user?.username ? user.username : ""} {user?.age ? ', ' + user.age : ""}
          </span>
          <span>{user?.membership ? user.membership : ""}</span>
          <span>
            <FaCircle
              className={
                user?.is_online && user.is_online == true
                  ? "online-circle"
                  : "offline-circle"
              }
            />{" "}
            {user?.is_online && user.is_online == true ? t("online") : t("offline")}
          </span>
        </div>
        <div className="user-details">
          <div className="each-detail vb-color">
            <span>
              {" "}
              <FaBirthdayCake /> {user?.age ? user.age + " " + t("years") : ""}{" "}
            </span>
          </div>
          <div className="each-detail vb-color">
            <span>
              {" "}
              <FaVenusMars /> {user?.gender ? user.gender : ""}
            </span>
          </div>
          <div className="each-detail vb-color">
            <span>
              {" "}
              <BsGlobe /> {user?.country ? user.country : ""}
            </span>
          </div>
          <div className="each-detail vb-color">
            <span>
              {" "}
              <FaRegBuilding /> {user?.city ? user.city : ""}
            </span>
          </div>
          <div className="each-detail vb-color">
            <span>
              {" "}
              <AiFillHeart /> {user?.civil_status ? user.civil_status : ""}
            </span>
          </div>
        </div>

        <div>
          <button style={{cursor:"default"}} className="main-btn pb-color t-color w-100 mg-top-25">
          <span>
            <strong>{user?.credits ? user.credits : 0} {t("credits")}</strong>
          </span>
          </button>
        </div>
        {
          user?.remaining_messages  && 
        <div>
          <button style={{cursor:"default"}} className="main-btn pb-color t-color w-100 mg-top-25">
          <span>
            <strong>{user?.remaining_messages != -1 ? user?.remaining_messages : <span>&infin;</span>} {t("remaining messages")}</strong>
          </span>
          </button>
        </div>
        }
        <div>
          <button className="main-btn tb-color f-white w-100 mg-top-25 howver">
          {t("My likes")}
          </button>
        </div>
      </div>
      {user?.pictures &&
          user.pictures.length > 0
        &&
        <> 
    <h5 className="mg-top-25">{t("My photos")}</h5>
      <div className="photo-box bb-color main-shadow-box">
        {user.pictures.slice(0,4).map((photo) => (
            <div key={"photoleft" + photo.id} className="each-image">
              <img src={photo.src} alt="profile-member-img" />
            </div>
          ))}
      </div>
      </>
}
      {featuredUsers && featuredUsers.length > 0 && 
      <>
      <h5 className="mg-top-25">{t("Featured users")}</h5>
      <div className="featured-users bb-color main-shadow-box">
      {featuredUsers &&
          featuredUsers.map((user) => (
            <Link
            key= {"featureduser" + user?.user_id }
            to={"/profile/" + user?.user_id}
            ><div className="flex-start glass-effect each-user">
            <div className="featured-users-each-image">
              <img src={user.picture} alt="members-img" className="main-round-image img-background" />
            </div>
            <div className="featured-users-info ">
            <span className="bld">{user.username}</span>
            <span>
            <FaCircle
              className={
                user?.is_online && user.is_online == true
                  ? "online-circle"
                  : "offline-circle"
              }
            />{" "}
            {user?.is_online && user.is_online == true ? t("online") : t("offline")}
          </span>
            </div>
            </div>
            </Link>
          ))}
      </div>
      </>
    }

    </>
  );
}

export default Profile;
