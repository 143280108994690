import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomeForm.css";
import banner1 from "../../../images/banner/01.png";
import config from "../../../config";
import { rangeNumber } from "../../../Utils/Utils";
import {useTranslation} from "react-i18next";

function HomeForm() {
  const { t, i18n, ready } = useTranslation();
  const navigate = useNavigate();
  const allGenders = [
    { label: "Select Gender", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" }
  ];

  const allAges = rangeNumber(18, 99);

  const [gender, setGender] = useState();
  const [genderPartner, setGenderPartner] = useState('female');
  const [ageMin, setAgeMin] = useState();
  const [civilStatus, setCivilStatus] = useState();
  const [city, setCity] = useState();



  function changeGender(e){
    setGender(e.target.value);
    console.log(e.target.value);
    let genderPartner = 'male';
    if(e.target.value == 'male'){
      genderPartner = 'female';
      console.log("bo");
    }
    setGenderPartner(genderPartner);
  }
  function postForm(e) {
    e.preventDefault();
    navigate("/signup", {
      state: { gender: gender, genderPartner: genderPartner, age: ageMin, civilStatus: civilStatus },
    });
  }
  return (
    <div className="container full-photo">
      <div className="intro-form">
        <h3>Introducing {process.env.REACT_APP_APP_NAME}</h3>
        <p>
          Serious Dating With <strong>{process.env.REACT_APP_APP_NAME} </strong> Your Perfect
          Match is Just a Click Away.
        </p>
        <form style={{ textAlign: "center" }} onSubmit={(e) => postForm(e)}>
          <div className="form-item">
            <label for="gender">I am a</label>
            <select
              id="gender"
              name="gender"
              onChange={changeGender}
            >
              {config.allGenders.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="form-item">
            <label for="genderPartner">Looking for</label>
            <select
              id="genderPartner"
              name="genderPartner"
              onChange={(e) => setGenderPartner(e.target.value)}
              value={genderPartner}
            >
              {config.allGenders.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <div className="form-item">
            <label for="ageMin">Age</label>

            <select
              id="ageMin"
              name="ageMin"
              value={ageMin}
              onChange={(e) => setAgeMin(e.target.value)}
            >
              {allAges.map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="form-item">
            <label for="civilStatus">Civil status</label>
            <select
              id="civilStatus"
              name="civilStatus"
              onChange={(e) => setCivilStatus(e.target.value)}
            >
              {[{ label: "Select Civil Status", value: "" }].concat(config.allCivilsStatus).map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>

          <button className="form-button tb-color f-white howver">
            {t("Find your partner")}
          </button>
        </form>
      </div>
    </div>
  );
}

export default HomeForm;
