import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
// import logo from "../../images/logo/logo_white.png";


import "./Contact.css";
import axios from "axios";
import config from "../../config";

let logo;
import(`../../images/${process.env.REACT_APP_WEBSITE_ID}/logo_white.png`).then((module) => {
  logo = module.default;
});

function Contact() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [inputChecked, setInputChecked] = useState(false);
  const { t, i18n, ready } = useTranslation();
  function postMessage(e) {
    e.preventDefault();

    if (name && email && message && inputChecked) {
      axios
        .post(
          config.url + "contact",
          {
            email: email,
            full_name: name,
            message: message,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            toast(t("Your message was sent successfully!"));
            setName("");
            setEmail("");
            setMessage("");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast(t("You need to complete required fields!"));
    }
  }

  return (
    <>
      <Breadcrumb pageTitle={t("Contact")} text = {t("Need assistance? Our support team is ready to help!")}/>
      <div className="container container-contact ">
        <div className="contact-form bb-color main-shadow-box">
          <form onSubmit={(e) => postMessage(e)}>
            <div>
              <input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="w-100"
                name="username"
                type="text"
                placeholder={t("Full name")}
              ></input>
            </div>
            <div>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="w-100"
                name="email"
                type="text"
                placeholder={t("Email")}
              ></input>
            </div>
            <div>
              <textarea
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                className="w-100"
                id="message"
                name="message"
                type="text"
                placeholder={t("Message")}
                rows="10"
              ></textarea>
            </div>
            <div>
              <label className="container-checkbox" htmlFor="accept-data">
                {/* <div className="checkbox-form"></div> */}
                <input
                  id="accept-data"
                  type="checkbox"
                  checked={inputChecked}
                  onChange={(e) => setInputChecked(!inputChecked)}
                ></input>

                <span className="checkmark"></span>
                {t("I accept that the above data be used to be contacted by the administrators of this website.")}
              </label>
            </div>
            <div style={{ textAlign: "end" }}>
              <button className="main-btn tb-color f-white howver">
                {t("Send message")}
              </button>
            </div>
          </form>
        </div>
        <div className="contact-right">
          <div className="contact-photo">
            <div className="contact-logo">
              {logo && <img src={logo} style={{objectFit: "contain"}} /> }
            </div>
            <br />
            <h3>{process.env.REACT_APP_APP_NAME}</h3>
            <p>
              {t("Personal data that will be collected through the contact form will be used to make a direct email connection with you.")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
