import React from "react";
import HomeForm from "../Components/Forms/HomeForm/HomeForm";
import "./Home.css";

function Home() {
    return (
        <>
            <div className="full-photo custom-photo">
                <HomeForm />
            </div>
        </>
    );
}

export default Home;
