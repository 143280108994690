import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import config from "../../config";
import axios from "axios";

import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import { AiOutlineSend, AiFillGift, AiFillDelete, AiFillLock} from "react-icons/ai";
import { BsEmojiSmile, BsCardImage, BsFillChatFill, BsPlayFill, BsArrowThroughHeartFill } from "react-icons/bs";

import Modal from "../Modal/Modal";
import { FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ImageModal from "../ImageModal/ImageModal";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import EmojiPicker from "emoji-picker-react";
import AudioRecorder from "../AudioRecorder/AudioRecorder";
import PhotoBlockModal from "../PhotoBlockModal/PhotoBlockModal";


function Chat({ receiver_id }) {

    const isAdmin = localStorage.getItem('is_admin') === 'true';

    // const { receiver_id } = useParams();
    const [conversations, setConversations] = useState();
    const [currentMessage, setCurrentMessage] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [selectedTypeFile, setSelectedTypeFile] = useState();
    const [selectedFileName, setSelectedFileName] = useState();
    const [preview, setPreview] = useState();
    const [nrMessages, setNrMessages] = useState(5);
    const [nrPage, setNrPage] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [show, setShow] = useState(false);
    const [displayModalPhoto, setDisplayModalPhoto] = useState();
    const [modalPhotoSrc, setModalPhotoSrc] = useState();

    const [isEmojiOpen, setIsEmojiOpen] = useState();

    const [unlockedPhotos,setUnlockedPhotos] = useState([]);
    const delay = 10;
    const { t, i18n, ready } = useTranslation();
    const [user, setUser] = useState();
    const [currentUser, setCurrentUser] = useState();
    const navigate = useNavigate();

    
    const [warningMessage,setWarningMessage] = useState(false);
    const [photoCredits,setPhotoCredits] = useState(false);

    const [pricingPlans, setPricingPlans] = useState([]);

    // Modal Gift
    const [giftModalId, setGiftModalId] = useState(false);
    const [displayModalGift, setDisplayModalGift] = useState();
    const [displayModalFlirt, setDisplayModalFlirt] = useState();
    const [displayModalUnlockCredits, setDisplayModalUnlockCredits] = useState();
    const [giftModalText, setGiftModalText] = useState();
    const [giftCredits, setGiftCredits] = useState();

    const [flirtModalId, setFlirtModalId] = useState(false);
    const [flirtModalText, setFlirtModalText] = useState();
    const [flirtModalSubtitle, setFlirtModalSubtitle] = useState();
    const [flirtCredits, setFlirtCredits] = useState();

    const [creditsPhotoModal, setCreditsPhotoModal] = useState(false);

    useEffect(() => {
        const getProfileReceiverDetails = async () => {
            if (receiver_id === undefined) return;
            const response = await axios.get(
                config.url + "users/id/" + receiver_id,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            setUser(response.data.user);
        };

        const getProfileDetails = async () => {
            const response = await axios.get(config.url + "users/id/me", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            });
            setCurrentUser(response.data.user);
          };
        

        getProfileReceiverDetails();
        getProfileDetails();

    }, []);



    const getConversations = async () => {
        const response = await axios.get(config.url + "conversations", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (response.data) {
            setConversations(response.data);
        }
    };
    useEffect(() => {
        getConversations();
    }, []);

    const [messages, setMessages] = useState([]);
    useEffect(() => {
        getChatMessages().then(() => scrollToBottom());
    }, []);

    useEffect(() => {
        let timer1 = setTimeout(() => {
            setShow(!show);
            isNewMessages();
        }, delay * 1000);

        return () => {
            clearTimeout(timer1);
        };
    }, [show]);

    const isNewMessages = async () => {
        if (receiver_id) {
            const response = await axios.get(
                config.url + "messages/user/new?user_id=" + receiver_id,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.data.new_messages_number) {
                getChatMessages().then(() => scrollToBottom());
            }
        }
    };


    const deleteMessage = async (event) => {
        const message_id = event.currentTarget.dataset.id;
        const confirmDelete = window.confirm(`Are you sure you want to delete this message?`);

        if (confirmDelete) {

            await axios.delete(config.url + "messages/delete/" + message_id, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              });
              getChatMessages();
        }
      };

    const getGifts = async () => {
        return await axios.get(config.url + "gifts/", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
    };
    const getFlirts = async () => {
        return await axios.get(config.url + "flirts/", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
    };

    useEffect(() => {
        getPricingPlans();
    }, []);

    const getPricingPlans = async () => {
        if (localStorage.getItem("token")) {
            const response = await axios.get(config.url + "products", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });

            setPricingPlans(response.data);
        }
    };

    const setStateModalGift = async (childrenData) => {
        if (childrenData == false && giftModalId) {
            setDisplayModalGift(false);
        }
        if (childrenData && giftModalId) {
            axios
                .post(
                    config.url + "messages/gift/" + giftModalId,
                    { user_id: receiver_id },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                .then((result) => {
                    if (result.status === 200) {
                        toast(t("You send a gift"));
                        getChatMessages().then(() => scrollToBottom());
                    }
                })
                .catch((error) => {
                    toast(error.response.data.message);
                    setTimeout(() => {
                        navigate("/membership-credits");
                    }, 3500);
                });

            setDisplayModalGift(false);
        }
    };

    const setStateModalFlirt = async (childrenData) => {

        if (childrenData == false && flirtModalId) {
            setDisplayModalFlirt(false);
        }
        if (childrenData && flirtModalId) {
            axios
                .post(
                    config.url + "messages/flirt/" + flirtModalId,
                    { user_id: receiver_id },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                .then((result) => {
                    if (result.status === 200) {
                        toast(t("You send a flirt"));
                        getChatMessages().then(() => scrollToBottom());
                    }
                })
                .catch((error) => {
                    toast(error.response.data.message);
                    setTimeout(() => {
                        navigate("/membership-credits");
                    }, 3500);
                });

            setDisplayModalFlirt(false);
        }
    };

    const setStateModalUnlockPhoto = async (childrenData) => {
        if (childrenData == false && currentUnlock) {
            setDisplayModalUnlockCredits(false);
        }
        if (childrenData && currentUnlock) {
            axios
                .post(
                    config.url + "order/media/",
                    { message_id : currentUnlock },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                .then((result) => {
                    if (result.status === 200) {
                        toast(t("You unlocked a photo"));
                        if (currentUnlock && !unlockedPhotos.includes(currentUnlock)) {
                            setUnlockedPhotos([...unlockedPhotos, currentUnlock]);
                        }
                        // getChatMessages().then(() => scrollToBottom());
                    }
                })
                .catch((error) => {
                    toast(error.response.data.message);
                    setTimeout(() => {
                        navigate("/membership-credits");
                    }, 3500);
                });

            setDisplayModalUnlockCredits(false);
        }
    };

    function handleEmoji(objectEmoji) {
        const inputText = currentMessage != undefined ? currentMessage : "";
        setCurrentMessage(inputText + objectEmoji.emoji);
    }
    function loadMoreMessages() {
        setNrPage(nrPage + 1);
        getChatMessages(nrPage + 1);
    }

    const getChatMessages = async (nrPages = 1) => {
        if (receiver_id == undefined) return;
        const response = await axios.get(
            config.url +
                "messages?user_id=" +
                receiver_id +
                "&messages_per_page=" +
                nrMessages +
                "&page=" +
                nrPages,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (response.data.last_page > response.data.current_page) {
            setShowLoadMore(true);
        } else {
            setShowLoadMore(false);
        }

        let set = new Set();
        if (response.data.messages) {
            let unionArray = [...response.data.messages, ...messages].filter(
                (item) => {
                    if (!set.has(item.id)) {
                        set.add(item.id);
                        return true;
                    }
                    return false;
                },
                set
            );

            unionArray.sort((a, b) => a.id - b.id);
            setMessages(unionArray);
    
        }
    };

    function sendMessage(e) {
        e.preventDefault();

        if (selectedFile) {
            const formData = new FormData();

            formData.append(
                selectedTypeFile ? "video" : "image",
                selectedFile,
                selectedFileName
            );

         
            formData.append("receiver_id", receiver_id);

            if(photoCredits){
                formData.append('credits', photoCredits)
            }
       
            axios
                .post(config.url + "messages/media/add", formData, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then((result) => {
                    if (result.status === 200) {
                        toast(t("Your photo was succesfully sent!"));
                        setSelectedFile("");
                        setNrMessages(nrMessages + 1);
                        getChatMessages().then(() => scrollToBottom());
                        // window.location.reload();
                    }
                })
                .catch((error) => {
                    toast(error.response.data.message);
                });
        }
        if (currentMessage) {
            axios
                .post(
                    config.url + "messages/add",
                    {
                        receiver_id: receiver_id,
                        text: currentMessage,
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                .then((result) => {
                    if (result.status === 200) {
                        setCurrentMessage("");
                        setNrMessages(nrMessages + 1);
                        getChatMessages().then(() => scrollToBottom());
                        toast(t("You send a message!"));
                    }
                })
                .catch((error) => {
                    toast(error.response.data.message);
                    setTimeout(() => {
                        navigate("/membership-credits");
                    }, 3500);
                });
        }
    }

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    useEffect(() => {
        // scrollToBottom()
    }, [messages]);


    const [currentUnlock, setCurrentUnlock] = useState();
    const [unlockCredits, setUnlockCredits] = useState();

    const setModalUnlock = async (e) => {
        e.preventDefault();
        const messageId = e.target.getAttribute("data-id");
        const photoCredits = e.target.getAttribute("data-credits");

        if(messageId){
            setDisplayModalUnlockCredits(true);
            setUnlockCredits(photoCredits);
            setCurrentUnlock(messageId);

            // if (messageId && !unlockedPhotos.includes(messageId)) {
            //     setUnlockedPhotos([...unlockedPhotos, messageId]);
            //   }
        }
  

        // console.log(unlockedPhotos);
        // console.log(messageId, photoCredits);
  
      };

    const displayMessages = messages?.map((message, index) => (
        <div key={index + message.id} className="item">
            {(() => {
                if (message.text) {
                    return (
                        <div
                            key={"message" + message.id}
                            className={
                                "messages-container-center__each_message " +
                                (message.receiver_id == receiver_id
                                    ? "text-right"
                                    : "text-left")
                            }
                        >
                            <div
                                className={
                                    "messages-container-center-chat__message " +
                                    (message.receiver_id == receiver_id
                                        ? "left-bcolor"
                                        : "right-bcolor")
                                }
                            >
                              
                                {message.text} {isAdmin && <AiFillDelete onClick ={deleteMessage} data-id = {message.id} style ={{float:"right", cursor:"pointer"}}/>}
                                
                            </div>
                        </div>
                    );
                } else if (message.image && !message.is_gift && !message.is_flirt) {
                    return (
                        <div
                            key={"message" + message.id}
                            className={
                                "messages-container-center__each_message " +
                                (message.receiver_id == receiver_id
                                    ? "text-right"
                                    : "text-left")
                            }
                        >
                            <div
                                className={
                                    message.receiver_id == receiver_id
                                        ? "messages-container-right-chat__message"
                                        : "messages-container-left-chat__message"
                                }
                            >
                                <div className="pos-rel mg-left-10">
                                    {message.credits && !unlockedPhotos.includes(message.id.toString()) ? <div className="unlock-photo photo-messages-bl" data-id={message.id} data-credits={message.credits} onClick={setModalUnlock}><AiFillLock data-id={message.id} data-credits={message.credits}/> <span data-id={message.id} data-credits={message.credits}>{message.credits} credits</span> </div> : null}
                                    <img
                                        className={"messages-photo " + (message?.credits && !unlockedPhotos.includes(message.id.toString()) ? 'photo-blocked' : '') }
                                        src={message.image}
                                        onClick={handleImageClick}
                                    ></img>
                                   
                                     {isAdmin && <AiFillDelete onClick ={deleteMessage} data-id = {message.id} style ={{float:"right", cursor:"pointer", marginLeft:"5px"}}/> }
                                </div>

                            </div>
                        </div>
                    );
                } else if (message.is_gift || message?.is_flirt) {
                    return (
                        <div
                            key={"message" + message.id}
                            className={
                                "messages-container-center__each_message " +
                                (message.receiver_id == receiver_id
                                    ? "text-right"
                                    : "text-left")
                            }
                        >
                            <div
                                className={
                                    message.receiver_id == receiver_id
                                        ? "messages-container-center-chat__message flex-end-two left-bcolor"
                                        : "messages-container-center-chat__message flex-start-two right-bcolor"
                                }
                            >
                                {message.receiver_id == receiver_id &&
                                    (message?.is_flirt ? t("You send a flirt") : t("You send a gift"))}
                                <div
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                    }}
                                >
                                    <img
                                        className="gift-message"
                                        src={message.image}
                                        onClick={handleImageClick}
                                    ></img>
                                </div>
                                {message.receiver_id != receiver_id &&
                                    t("You received a gift!")}
                            </div>
                        </div>
                    );
                } else if (message.video) {
                    return (
                        <div
                            key={"message" + message.id}
                            className={
                                "messages-container-center__each_message " +
                                (message.receiver_id == receiver_id
                                    ? "text-right"
                                    : "text-left")
                            }
                        >
                            <div
                                className={
                                    message.receiver_id == receiver_id
                                        ? "messages-container-right-chat__message"
                                        : "messages-container-left-chat__message"
                                }
                            >
                                <div className="pos-rel">
                                    {message.credits && !unlockedPhotos.includes(message.id.toString()) ? <div className="unlock-photo photo-messages-bl" data-id={message.id} data-credits={message.credits} onClick={setModalUnlock}>
                                        <div data-id={message.id} data-credits={message.credits} onClick={setModalUnlock}>
                                            <div data-id={message.id} data-credits={message.credits}>  {t("Play video ")}</div>
                                            <div data-id={message.id} data-credits={message.credits}> <AiFillLock /> </div>
                                        
                                        </div>
                               
                                        <span data-id={message.id} data-credits={message.credits}>{message.credits} credits</span> 
                                      
                                    
                                    </div> : null}
                                    <video 
                                        className={"messages-video " + (message?.credits && !unlockedPhotos.includes(message.id.toString()) ? 'video-blocked' : '') }
                                        controls>
                                        <source
                                            src={message.video}
                                            type="video/mp4"
                                        />
                                    </video>
                                </div>
                            </div>
                        </div>
                    );
                } else if (message.audio) {
                    return (
                        <div
                            key={"message" + message.id}
                            className={
                                "messages-container-center__each_message " +
                                (message.receiver_id == receiver_id
                                    ? "text-right"
                                    : "text-left")
                            }
                        >
                            <div
                                className={
                                    message.receiver_id == receiver_id
                                        ? "messages-container-right-chat__message"
                                        : "messages-container-left-chat__message"
                                }
                            >
                                <div>
                                <audio controls>
                                    <source src={message.audio} />
                                </audio>
                                </div>
                            </div>
                        </div>
                    );
                }
            })()}
        </div>
    ));

    function handleImageClick(e) {
        setDisplayModalPhoto(true);
        if (e.target.getAttribute("src")) {
            setModalPhotoSrc(e.target.getAttribute("src"));
        }
    }

    const setStateModal = async (childrenData) => {
        if (childrenData) {
            setDisplayModalPhoto(false);
        }
    };

    const setStateModalCredits = async (childrenData) => {
    
        if (childrenData) {
            setCreditsPhotoModal(false);
            setPhotoCredits(childrenData);
        }
    };

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            setPhotoCredits(0);
            return;
        }

        if (e.target.files[0].type === "video/mp4") {
            setSelectedTypeFile("mp4");
        }

        setSelectedFile(e.target.files[0]);
        setSelectedFileName(e.target.files[0].name);

        setCreditsPhotoModal(true);
    };

    return (
        <>
            {
                <>
                    <div className="container">
                        {user && (
                            <>
                                <div className="modal-messenger mg-auto ">
                                    <div className="top-info-user">
                                        <Link
                                            to={"/profile/" + receiver_id}
                                            style={{ color: "white" }}
                                        >
                                            <div className="flex-start">
                                                <img
                                                    src={user?.main_pic}
                                                    className="top-photo-user img-background"
                                                    alt="profile-user-img"
                                                />
                                                <div className="top-details-user">
                                                    <span>
                                                        {user?.username
                                                            ? user.username
                                                            : ""}{" "}
                                                        {user?.age
                                                            ? "," + user.age
                                                            : ""}
                                                    </span>
                                                    <span>
                                                        <FaCircle
                                                            className={
                                                                user?.is_online &&
                                                                user.is_online ==
                                                                    true
                                                                    ? "online-circle"
                                                                    : "offline-circle"
                                                            }
                                                        />{" "}
                                                        {user?.is_online &&
                                                        user.is_online == true
                                                            ? t("online")
                                                            : t("offline")}
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                        <div className="top-buttons-user">
                                        </div>
                                    </div>
                                   
                                    <div className="messages-container-center__chat">
                                    {warningMessage && <div className="t-color alert  alert-danger mb-10">{t("This feature requires both you and your partner to have the same membership level.")}</div>}
                                        <div className="messages-container-center-chat__list-messages">
                                            
                                            {showLoadMore && (
                                                <div
                                                    className="messages-container-center-chat__show-messages"
                                                    onClick={loadMoreMessages}
                                                >
                                                    {t("Load more")}
                                                </div>
                                            )}
                                            {displayMessages}
                                            <div
                                                id="yoooyoyo"
                                                ref={messagesEndRef}
                                            />
                                        </div>
                                        <form onSubmit={sendMessage}>
                                            <div className="messages-container-center-chat__send_message">
                                                <div className="send-message__left">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="file"
                                                                style={{
                                                                    display:
                                                                        "none",
                                                                }}
                                                                onChange={
                                                                    onSelectFile
                                                                }
                                                            />

                                                            <BsCardImage />
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <BsEmojiSmile
                                                            onClick={() =>
                                                                setIsEmojiOpen(
                                                                    !isEmojiOpen
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    {/* <AudioRecorder onRecordingComplete={handleRecordingComplete} /> */}
                                                </div>
                                                <div className="send-message__center">
                                                    <input
                                                        type="text"
                                                        placeholder={t(
                                                            "Type your message"
                                                        )}
                                                        name="current-message"
                                                        value={currentMessage}
                                                        onChange={(e) =>
                                                            setCurrentMessage(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div className="send-message__right">
                                                    <AiOutlineSend
                                                        onClick={(e) =>
                                                            sendMessage(e)
                                                        }
                                                    />
                                                </div>
                                                {isEmojiOpen && (
                                                    <div className="picker-emoji">
                                                        <EmojiPicker
                                                            onEmojiClick={
                                                                handleEmoji
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                {selectedFile &&
                                                    !selectedTypeFile && (
                                                        <img
                                                            className="message-image-upload"
                                                            src={preview}
                                                        />
                                                    )}
                                                {selectedFile &&
                                                    !selectedTypeFile && (
                                                        <>
                                                        <span
                                                            style={{
                                                                marginLeft:
                                                                    "15px",
                                                            }}
                                                        >
                                                            {t(
                                                                "You selected this photo but don't forget to send it"
                                                            )}
                                                             
                                                        </span>

                                                   {photoCredits && <> <br/> {t("Number of credits")}: {photoCredits} </>}
                                                        </>
                                                    )}
                                                {selectedFile &&
                                                    selectedTypeFile && (
                                                        <>
                                                        <video
                                                            className="messages-video"
                                                            controls
                                                        >
                                                            <source
                                                                src={preview}
                                                                type="video/mp4"
                                                            />
                                                        </video>
                                                        {photoCredits && <> <br/> {t("Number of credits")}: {photoCredits} </>}
                                                        </>
                                                    )}
                                            </div>
                                            {selectedFile &&
                                                selectedTypeFile && (
                                                    <span
                                                        style={{
                                                            marginLeft: "15px",
                                                        }}
                                                    >
                                                        {t(
                                                            "You selected this video but don't forget to send it"
                                                        )}
                                                    </span>
                                                )}
                                        </form>
                                    </div>
                                </div>
                            </>
                        )}
                        {!user && (
                            <p className="message-no-conversation">
                                {conversations && conversations.length > 0
                                    ? t(
                                          "You haven't opened a conversation yet!"
                                      )
                                    : t(
                                          "You don't have conversations yet, go to the members page and find someone!"
                                      )}
                            </p>
                        )}
                        {displayModalPhoto && modalPhotoSrc && (
                            <ImageModal
                                src={modalPhotoSrc}
                                close={setStateModal}
                            />
                        )}
                        {displayModalGift && giftModalId && (
                            <Modal
                                text={giftModalText}
                                credits={giftCredits}
                                confirm={setStateModalGift}
                            />
                        )}

                        {displayModalFlirt && flirtModalId && (
                            <Modal
                                text={flirtModalText}
                                credits={flirtCredits}
                                confirm={setStateModalFlirt}
                                subtitle={flirtModalSubtitle}
                            />
                        )}
                        {displayModalUnlockCredits && currentUnlock && (
                            <Modal
                                text={t("Are you sure you want to unlock this photo?")}
                                credits={unlockCredits}
                                confirm={setStateModalUnlockPhoto}
                            />
                        )}
                        {creditsPhotoModal && currentUser?.paidMediaMessage && (
                            <PhotoBlockModal
                                confirm={setStateModalCredits}
                            />
                        )}
                    </div>
                </>
            }
        </>
    );
}

export default Chat;
