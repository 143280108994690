function Loading() {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        fontSize: "2.5rem",
        transform: "translate(-50%, -50%)"
      }}
    >
      Loading...
    </div>
  );
}

export default Loading;
