import React, { useState, useRef } from "react";
import { FaMicrophone, FaTimes, FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const AudioRecorder = ({ onRecordingComplete }) => {
    const [recording, setRecording] = useState(false);
    const [recorded, setRecorded] = useState(false); // new state to hold whether recording is done
    const [audioChunks, setAudioChunks] = useState([]);
    const [duration, setDuration] = useState(0);
    const mediaRecorderRef = useRef(null);
    const timerRef = useRef(null);
    const { t } = useTranslation();

    const handleStartRecording = () => {
        setRecording(true);
        setAudioChunks([]);
        setDuration(0);

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;
                mediaRecorder.addEventListener(
                    "dataavailable",
                    handleDataAvailable
                );
                mediaRecorder.start();
                timerRef.current = setInterval(() => {
                    setDuration((prevDuration) => prevDuration + 1);
                }, 1000);
            })
            .catch((error) => {
                console.error("Error accessing microphone:", error);
            });
    };

    const handleDataAvailable = (event) => {
        if (event.data.size > 0) {
            setAudioChunks((prevChunks) => [...prevChunks, event.data]);
        }
    };

    const handleStopRecording = () => {
        if (
            mediaRecorderRef.current &&
            mediaRecorderRef.current.state !== "inactive"
        ) {
            mediaRecorderRef.current.stream
                .getTracks()
                .forEach((track) => track.stop());
            mediaRecorderRef.current.stop();
        }
        setRecording(false);
        setRecorded(true); // set recorded state to true
        clearInterval(timerRef.current); // Clear the interval
    };

    const handleCancelRecording = () => {
        setRecorded(false);
        setAudioChunks([]);
        setDuration(0);
    };

    const handleSendRecording = () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        onRecordingComplete(audioBlob);
        setRecorded(false);
        setAudioChunks([]);
        setDuration(0);
    };

    return (
        <div>
            <FaMicrophone onClick={handleStartRecording} />

            {recording && (
                <div className="lightbox" style ={{zIndex: "1000"}}>
                    <div className="lightbox-content vb-color">
                        <div className="recording-info">
                            <p>Recording in progress... ({duration}s)</p>
                        </div>
                        <div className="d-flex">
                            <button
                                className="main-btn main-btn-header pb-color t-color"
                                onClick={handleStopRecording}
                            >
                                <FaCheck /> {t("Stop recording")}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {recorded && (
                <div className="lightbox" style ={{zIndex: "1000"}}>
                    <div className="lightbox-content vb-color">
                        <div className="recording-info">
                            <p>Recording complete! ({duration}s)</p>
                        </div>
                        <div className="d-flex">
                            <button
                                className="main-btn main-btn-header pb-color t-color"
                                onClick={handleCancelRecording}
                            >
                                <FaTimes /> {t("Cancel")}
                            </button>
                            <button
                                className="main-btn tb-color f-white hover"
                                onClick={handleSendRecording}
                            >
                                <FaCheck /> {t("Send")}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AudioRecorder;
