import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
// import axiosInstance from './helpers/Axios';
import axios from "axios";
import config from "./config";

const loadResources = async (locale) => {
    return await axios
        .get(
            config.url + "translate/texts",
            {
                headers: {
                    "X-localization": locale,
                },
            },
            { params: { lang: locale } }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

const backendOptions = {
    loadPath: "{{lng}}|{{ns}}",
    request: (options, url, payload, callback) => {
        try {
            const [lng] = url.split("|");
            loadResources(lng).then((response) => {
                callback(null, {
                    data: response,
                    status: 200,
                });
            });
        } catch (e) {
            console.error(e);
            callback(null, {
                status: 500,
            });
        }
    },
};

i18n.use(LanguageDetector)
    .use(backend)
    .init({
        backend: backendOptions,
        fallbackLng: "en",
        debug: false,
        load: "languageOnly",
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            wait: true,
        },
    });

export default i18n;
