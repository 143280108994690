import React,{useState,useEffect} from "react";
import "./Modal.css";
import {useTranslation} from "react-i18next";

function ModalAge() {

  const [confirm,setConfirm] = useState(localStorage.getItem('confirm18') ? localStorage.getItem('confirm18') : undefined);
  const { t, i18n, ready } = useTranslation();
  useEffect(() => {
    if(confirm == false){
        window.location.href = "https://www.google.com/";
    }else if(confirm == true){
        localStorage.setItem('confirm18','true');
    }
    
  },[confirm]);

  return (
    <>    
    {confirm == undefined &&
    <div className="modal-confirmation">
      <div className="modal-content">
        <div className="modal-header text-center">
          <p className="w-100"><strong>{t("Are you 18 or over?")}</strong></p>
        </div>
        <div className="modal-body ">
          <button className="main-btn tb-color p-color w-40" onClick={() => setConfirm(true)}>{t("Yes")}</button>
          <button className="main-btn tb-color p-color w-40" onClick={() => setConfirm(false)}>{t("No")}</button>
        </div>
      </div>
    </div>
}
    </>

  );
}

export default ModalAge;
