import React,{useState,useEffect,useRef} from "react";
import "./ImageModal.css";
import {useTranslation} from "react-i18next";
import {AiOutlineClose} from  "react-icons/ai";

function Modal(props) {

  const [close,setClose] = useState();
  const { t, i18n, ready } = useTranslation();
  const ref = useRef(null);
  const { onClickOutside } = props;
  useEffect(() => {
    props.close(close);
    document.body.classList.add('no-overflow');
    if(close){
      document.body.classList.remove('no-overflow');
    }
  },[close]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        setClose(true);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);



  return (
    <div  className="modal-image">
      <AiOutlineClose className="close" onClick={() => setClose(true)}/>
      <div className="modal-content-image">
         <img ref={ref} src={props.src} alt="Image zoom" />
      </div>
    </div>
  );
}

export default Modal;
