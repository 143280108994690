import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";

function ModalProm() {
    const navigate = useNavigate();
    const [remainingSeconds, setRemainingSeconds] = useState(0);
    const [remainingClosedSeconds, setRemainingClosedSeconds] = useState(0);
    const [showCountdown, setShowCountdown] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const first_login_date = localStorage.getItem("first_login_date");
        const seconds = Math.floor(
            (new Date() - new Date(first_login_date)) / 1000
        );

        if (seconds < 3600) {
            setRemainingSeconds(1800 - seconds);
        }

        const last_closed_date = localStorage.getItem("last_closed_date");
        if (last_closed_date) {
            setShowModal(false);
            const secondsClosed = Math.floor(
                (new Date() - new Date(last_closed_date)) / 1000
            );
            setRemainingClosedSeconds(secondsClosed);
        } else {
            setShowModal(true);
        }
    }, []);
    const { t, i18n, ready } = useTranslation();

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (remainingSeconds > 0) {
                setRemainingSeconds((prev) => prev - 1);
                setShowCountdown(true);
            } else {
                setShowCountdown(false);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [remainingSeconds]);

    useEffect(() => {
        if (remainingClosedSeconds > 0) {
            const intervalId = setInterval(() => {
                setRemainingClosedSeconds((prev) => prev + 1);
                if (remainingClosedSeconds > 600) {
                    setShowModal(true);
                    localStorage.removeItem("last_closed_date");
                    setRemainingClosedSeconds(0);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [remainingClosedSeconds]);

    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    return (
        <>
            {showCountdown && showModal && (
                <div className="modal-confirmation">
                    <div className="modal-content modal-prom">
                        <div className="modal-header d-flex text-center flex-column ">
                            <p className="close-icon">
                                <AiOutlineClose
                                    onClick={() => {
                                        setShowModal(false);
                                        localStorage.setItem(
                                            "last_closed_date",
                                            new Date()
                                        );
                                        setRemainingClosedSeconds(1);
                                    }}
                                />
                            </p>
                            <div className="w-100">
                                <div>
                                    <strong>{t("NEW CUSTOMER BONUS!")}</strong>
                                </div>
                                <div>
                                    {" "}
                                    {t(
                                        "GET 150 BONUS CREDITS ON YOUR NEXT MEMBERSHIP PURCHASE"
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>
                                <strong>
                                    {" "}
                                    {t("This offer expires after")}
                                </strong>
                            </p>
                        </div>
                        <div className="countdown-container">
                            <div className="countdown-timer">
                                00:{minutes < 10 ? "0" : ""}
                                {minutes}:{seconds < 10 ? "0" : ""}
                                {seconds}
                            </div>
                            <div>
                                <button
                                    className="main-btn p-color"
                                    onClick={() => {
                                        setShowModal(false);
                                        localStorage.setItem(
                                            "last_closed_date",
                                            new Date()
                                        );
                                        setRemainingClosedSeconds(1);
                                        navigate("/membership-credits");
                                    }}
                                >
                                    {t("GET YOUR BONUS NOW")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showCountdown && (
                <div
                    className="countdown-bottom"
                    onClick={() => {
                        localStorage.removeItem("last_closed_date");
                        setRemainingClosedSeconds(0);
                        setShowModal(true);
                    }}
                >
                    {" "}
                    <span>
                        {" "}
                        {t(
                            "GET 150 BONUS CREDITS ON YOUR NEXT MEMBERSHIP PURCHASE"
                        )}
                    </span>
                    {t("This offer expires after") + " "}
                    00:{minutes < 10 ? "0" : ""}
                    {minutes}:{seconds < 10 ? "0" : ""}
                    {seconds}
                </div>
            )}
        </>
    );
}

export default ModalProm;
