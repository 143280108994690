import {useEffect,useState} from 'react'
import { useParams } from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";

function ActivateAccount() {
const { token } = useParams();
const [showMessage,setShowMessage] = useState(false);
const { t, i18n, ready } = useTranslation();

// function activateAccount() {
//     const sendActivateRequest = async () => {

//         return await axios.post(config.url + "users/activate", {token: token});
//     };

//     sendActivateRequest()
//         .then((result) => {
//             if (result.status === 200){
//                 setShowMessage(true);
//                 toast(t("Account activated successfully"));
//             } 
//         })
//         .catch((error) => {
//             toast(t("There's a problem with account activation!"));
//         });
// }

// useEffect(() => {
//     if(token){
//         activateAccount();
//     }
// },[])

  return (
    <>
    {<div className="container checkout-container mg-top-auto">
            <div className="checkout-details">
                <div>
                    {/* <img src={membershipImg}></img> */}
                </div>
            {/* <div style={{backgroundColor: "white", borderRadius: "50%"}} className="message-success">
                <AiFillCheckCircle />
                </div> */}
            <div className="checkout-header">
                
                    {" "}
                    <h2>Activation account</h2>
                    <span>{t("Your account needs to be activated manually by an administrator!")}</span>
                    {/* <h2>{showMessage ? t("You're welcome!") : t("Activation error")}</h2>{" "} */}
                    {/* <span>{showMessage ?  t("Your account has been activated successfully!") : t("There's a problem with account activation!") }</span> */}
                </div>
            </div>
        </div>}
    </>
    

  )
}

export default ActivateAccount