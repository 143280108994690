import React,{useState,useEffect} from "react";
import "./CreditsModal.css";
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import {AiFillCheckCircle, AiOutlineClose} from 'react-icons/ai';
import { useNavigate } from "react-router-dom";


function CreditsModal(props) {

  const navigate = useNavigate();

  const [errorMessage,setErrorMessage] = useState();
  const [successMessage,setSuccessMessage] = useState(false);
  const [confirm,setConfirm] = useState();
  const [creditsNumber,setCreditsNumber] = useState();
  const [close,setClose] = useState();
  const { t, i18n, ready } = useTranslation();

  function isNumber(str) {
    if (str.trim() === '') {
      return false;
    }
  
    return !isNaN(str);
  }

  useEffect(() => {
    props.close(close);
    document.body.classList.add('no-overflow');
    if(close){
      document.body.classList.remove('no-overflow');
    }
  });


  function postSendCredits(e){
    e.preventDefault();

    let is_error = false;
    setErrorMessage("");
    if(creditsNumber){
      if(!isNumber(creditsNumber)){
      setErrorMessage(t("Please enter a number!"));
      is_error = true;
      }
      if(isNumber(creditsNumber) && creditsNumber == 0){
      setErrorMessage(t("Number must be greater than zero!"));
      is_error = true;
      }
    }
    else{
      is_error = true;
      setErrorMessage(t("This field can't be empty!"));
    }

    if(!is_error && props.sendTo){
      axios
      .post(
        config.url + "credits/send",
        {
          user_id: props.sendTo,
          credits: creditsNumber
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          toast("Credits sent successfully!");
          setSuccessMessage(true);
        }
      })
      .catch((error) => {
        if(error.response.data.message){
          setErrorMessage(error.response.data.message);
          setTimeout(() => {
            window.location.href = "/membership-credits";
        }, 3500);

        }

      });
    }

  }


  return (
    <div className="modal-confirmation">
      <div className="modal-content-credits">
        <AiOutlineClose className="close-inside-modal" onClick={() => setClose(true)}/>
        {!successMessage && <>
        <div className="modal-header text-center">
          <p>{t("Type how many credits you want to send to ") + "  "} <span className="bld">{props.sendToUsername}</span></p>
        </div>
        <form
            style={{ textAlign: "center", marginTop: "20px" }}
            onSubmit={(e) => postSendCredits(e)}
          >
            <div className="form-login-item">
              <input
                className={"w-100"}
                type="text"
                value={creditsNumber}
                onChange={(e) => {
                  setCreditsNumber(e.target.value);
                }}
                placeholder={t("Number of credits")}
              />
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            {/* {isUsername && <div className="error-message">{isUsername}</div>} */}
            <button className="main-btn tb-color howver p-color w-40" onClick={(e) => postSendCredits(e)}>{t("Send")}</button>
          </form>
          </>
          }
          {successMessage && <div className="message-success">
            <AiFillCheckCircle />
            <span>{creditsNumber} {t("credits has been sent successfully to") +" "+ props.sendToUsername}</span>
            <button className="main-btn tb-color p-color w-40" onClick={() => setClose(true)}>{t("Go back")}</button>
            </div>}
      </div>
    </div>
  );
}

export default CreditsModal;
