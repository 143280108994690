import React, { useState, useEffect } from "react";
import "./ProfileCardsMembers.css";
import profileGirl from "../../images/myphotos/profilegirl.png";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { AiOutlineSearch } from "react-icons/ai";
import SelectSearcher from "../SelectSearch/SelectSearch";
import Pagination from "../Pagination/Pagination";
import loadingGif from "../../images/myphotos/loading2.gif"
import {useTranslation} from "react-i18next";
import {
  FaCircle,
} from "react-icons/fa";

function ProfileCardsMembers() {
  const { t, i18n, ready } = useTranslation();
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  let [queryUsername] = "";
  let [currentPageLink] = "1";
  let [queryGender] = "";
  let [queryAge] = "";
  let [queryCountryCode] = "";
  let [queryOnlineUsers] = "";

  const [currentPage,setCurrentPage] = useState(1);
  const [objectSelectSearch,setObjectSelectSearch] = useState();
  let userPerPage = "8";
  if (searchParams) {
    queryUsername = searchParams.get("q");
    queryGender = searchParams.get("gender");
    queryAge = searchParams.get("age");
    queryOnlineUsers = searchParams.get("online");
    currentPageLink = searchParams.get("page");
    queryCountryCode = searchParams.get("countryCode");

  }

  useEffect(() => {
    if (searchParams) {
      queryUsername = searchParams.get("q");
      queryGender = searchParams.get("gender");
      queryCountryCode = searchParams.get("countryCode");
      currentPageLink = searchParams.get("page");

    }
    if(currentPageLink){
      setCurrentPage(currentPageLink);
    }

  },[])

  const allGenders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" }
  ];

  const [hover, setHover] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [members, setMembers] = useState([]);
  const [gender, setGender] = useState(queryGender);
  const [inputSearch, setInputSearch] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState(queryCountryCode);
  const [onlineUsers, setOnlineUsers] = useState(queryOnlineUsers);
  const [isPosted, setIsPosted] = useState(false);


  const [totalPags,setTotalPags] = useState(1);

  const handleCountry = async (childrenData) => {
    setCountry(childrenData);
  };

  const [age, onChange] = useState(queryAge > 18 ? queryAge : 18);

  useEffect(() => {
    if(queryUsername || queryCountryCode || queryAge || onlineUsers){
      setDisplayFilters(true);
    }

    if(queryCountryCode){
      setObjectSelectSearch(1);
    }

    if(queryUsername){
        setInputSearch(queryUsername);
      }
    getMembers();
  }, [currentPage]);

  const getMembers = async () => {
    let extraFilters = "";
    extraFilters = "?page=" + currentPage;
    if (queryUsername) {
      extraFilters += "&query=" + queryUsername;
    }
    // if(queryGender){
    //   extraFilters += "&gender=" + queryGender;
    // }
    if(queryAge > 18){
      extraFilters += "&max_age=" + queryAge;
    }
    if(queryOnlineUsers){
      extraFilters += "&online_only=" + queryOnlineUsers;
    }
    if(queryCountryCode){
      extraFilters += "&country_code=" + queryCountryCode;
    }

    extraFilters += "&users_per_page=" + userPerPage;



    
    
    const response = await axios.get(config.url + "users" + extraFilters, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if(response.data.users){
      setTotalPags(response.data.last_page);
        setMembers(response.data.users);
    }

  };
  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    const response = await axios.get(config.url + "countries");
    response.data.countries.map((country) => {
      if(country.code == queryCountryCode){
        console.log({value: country.code, label: country.name });
        setObjectSelectSearch({value: country.code, label: country.name });
      }
      countryList.push({ value: country.code, label: country.name })
      }
    );
  };

  function postSearch(e) {
    e.preventDefault();
    setIsPosted(true);

    const currentUrlPage = constructSearchLink();
    history(currentUrlPage);
    window.location.reload();
   
  }

  const resetFilters = function (e) {
    e.preventDefault();
    // setGender(false);
    setCountry(false);
    setOnlineUsers(false);
    onChange(18);

    let currentUrlPage = window.location.pathname;
    if(inputSearch){
      currentUrlPage += "?q=" + inputSearch;
    }
    
    history(currentUrlPage);
    window.location.reload();
  };

  const addFilters = function (e) {
    
    e.preventDefault();
 
    const currentUrlPage = constructSearchLink();
    history(currentUrlPage);
    window.location.reload();



  };


  function constructSearchLink(){
    let currentUrlPage = window.location.pathname;
    const params = new URLSearchParams();

    if(inputSearch){
      currentUrlPage += "?q=" + inputSearch;
    }

    // if(gender){
    //   params.append('gender', gender);
    // }
    if(age > 18){
      params.append('age', age);
    }
    if(country){
      params.append('countryCode', country);
    }
    if(onlineUsers){
      params.append('online', 1);
    }
    if(params && !inputSearch){
      currentUrlPage = currentUrlPage + '?';
    }
    if(params.toString()){
      if(inputSearch){
        currentUrlPage += '&';
      }
      currentUrlPage += params.toString();
    }

    return currentUrlPage;
  }

  

  const displayMembers = members.map((member, index) => (
    <Link
      key={member.id + member.username + index}
      className="profile-member-container"
      to={"/profile/" + member.user_id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="profile-member-img img-background">
        <img src={member.image} alt="profile-member-img img-background" />
      </div>
      <div className="profile-member-name bb-color">
        <span className="bld">{member.username}   </span>       <span>
            <FaCircle
              className={
                member?.is_online && member.is_online == true
                  ? "online-circle"
                  : "offline-circle"
              }
            />{" "}
            {member?.is_online && member.is_online == true ? t("online") : t("offline")}
          </span>
        <span>{member.city}, {member.country}</span>
      </div>
    </Link>
  ));

  return (
    <>
      <div className="container-search bb-color main-shadow-box">
        <form onSubmit={(e) => postSearch(e)}>
          <div className="profile-members-search ">
            <div className="profile-members-search-elements vb-color">
              <input
                type="text"
                className="profile-members-search-input"
                placeholder={t("Search members")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
              />
           
              <AiOutlineSearch
                type="submit"
                onClick={(e) => postSearch(e)}
                style={{ fontSize: "1.4rem", cursor: "pointer" }}
              />
             
            </div>
            <div className="profile-members-filter">
              <button
                onClick={() => setDisplayFilters(!displayFilters)}
                className="main-btn tb-color f-white howver"
                type="button"
              >
               {t("Advanced filters")}
              </button>
            </div>
          </div>
        </form>
        {(displayFilters)&& (
          <div className="profile-members-search-filters bb-color">
            <div className="search-filter-elements">
              <div className="slider-parent">
                <input
                  type="range"
                  min="18"
                  max="99"
                  value={age}
                  onChange={({ target: { value: radius } }) => {
                    onChange(radius);
                  }}
                />
                <div className="buble">{"max " + age + " years"}</div>
              </div>
              {/* <select
                id="gender"
                name="gender"
                className="main-input"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                {[{ label: "Select Gender", value: "" }]
                  .concat(allGenders)
                  .map((option) => (
                    <option key={"city" + option.value} value={option.value}>{option.label}</option>
                  ))}
              </select> */}
              <div>
        {true && 
                <SelectSearcher
                  parrentInputValue={handleCountry}
                  options={countryList}
                  name="country"
                  id="country"
                  error=""
                  selectedOption = {objectSelectSearch}
                  placeholder="Select country"
                />
        }
              </div>
              <div className="flex-align">
                <input
                  className="form-ceckbox"
                  type="checkbox"
                  value={onlineUsers}
                  checked={onlineUsers}
                  id="checkOnlineUsers"
                  onChange={(e) => setOnlineUsers(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="checkOnlineUsers">
                  Show online users
                </label>
              </div>
            </div>

            <div className="filter-buttons">
              <button className="main-btn tb-color f-white howver" onClick={addFilters}>
                Add filters
              </button>
              <button className="main-btn pb-color t-color" onClick={resetFilters}>
                Reset Filters
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="container-wrap">
      {members && displayMembers}
      {members.length == 0 && <p style={{margin:"auto" , marginBottom:"25px"}}>{t("No user was found")}</p>}

      </div>
      <Pagination
            pageLimit={5}
            dataLimit={totalPags}
            currentPageLink = {currentPage}
            currentPagePagination={(value) => setCurrentPage(value)}
          />
    </>
  );
}

export default ProfileCardsMembers;
