import React, { useState, useEffect } from "react";
import "./Pagination.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

function Pagination({
  pageLimit, 
  currentPageLink,
  dataLimit,
  currentPagePagination,
}) {

  const [pages,setPages] = useState(dataLimit);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    currentPagePagination(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setPages(dataLimit);
  },[dataLimit])

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    let paginationGroup = new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    if(paginationGroup[paginationGroup.length -1] > dataLimit){
        paginationGroup = paginationGroup.slice(0,dataLimit - paginationGroup[0] + 1);
    }
    return paginationGroup;
  };

  return (
    <div>
      <div className="pagination">
        {/* previous button */}
        <AiOutlineArrowLeft
          onClick={goToPreviousPage}
          className={`prev ${currentPage === 1 ? "disabled" : ""}`}
        />
        {/* show page numbers */}
        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={changePage}
            className={`paginationItem ${
              currentPage === item ? "active-pag" : null
            }`}
          >
            <span>{item}</span>
          </button>
        ))}
        {/* next button */}
        <AiOutlineArrowRight
          onClick={goToNextPage}
          className={`next ${currentPage === pages ? "disabled" : ""}`}
        />
      </div>
    </div>
  );
}

export default Pagination;
