import React, { useState, useEffect } from "react";
import { RiImageAddFill } from "react-icons/ri";
import { toast } from 'react-toastify';
import axios from "axios";
import config from "../../config";
import Modal from "..//Modal/Modal";
import {useTranslation} from "react-i18next";

function ImageUpload() {
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [preview, setPreview] = useState();
  const [displayModal, setDisplayModal] = useState(false);
  const { t, i18n, ready } = useTranslation();
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    setSelectedFileName(e.target.files[0].name);
  };

  const setStateModal = async (modalValue) => {
    if (modalValue === true || modalValue === false) {
      setDisplayModal(false);

      if (modalValue === true) {
        const formData = new FormData();
        formData.append("image", selectedFile, selectedFileName);
        axios
          .post(config.url + "users/picture/upload", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((result) => {
            if (result.status === 200) {
              toast("You have successfully added a new image!");
              window.location.reload();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const savePhoto = () => {
    if (selectedFile && selectedFileName) {
      setDisplayModal(true);
    }
  };

  return (
    <div className="pos-rel">
      <label className="custom-file-upload tb-color ">
        <input
          type="file"
          style={{ display: "none" }}
          onChange={onSelectFile}
        />

        {selectedFile ? <img src={preview} /> : <RiImageAddFill />}
      </label>
      {selectedFile && (
        <div
          className="pos-abs w-100"
          style={{ textAlign: "center", bottom: "20px" }}
        >
          <button className="main-btn" onClick={savePhoto}>
          {t("Save this photo")}
          </button>
        </div>
      )}
      {displayModal && (
        <Modal
          text={t("Are you sure you want to save this photo?")}
          confirm={setStateModal}
        />
      )}
    </div>
  );
}

export default ImageUpload;
