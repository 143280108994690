import React from "react";
import {useTranslation} from 'react-i18next';

function Thankyou() {
  const { t, i18n, ready } = useTranslation();
    return (
        <div className="container checkout-container">
            <div className="checkout-details">
            <div className="checkout-header">
                
                    {" "}
                    <h2>{t("Thank you!")}</h2>{" "}
                    <span>{t("Now you can enjoy the benefits of the chosen package")}</span>
                </div>
            </div>
        </div>
    );
}

export default Thankyou;
