import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Vonage from './Vonage';
import config from "../../config";
import axios from 'axios'
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AudioPlayer from './AudioPlayer';


const VonageCall = ({ userId }) => {

  const [buttonDisabled, setButtonDisabled] = useState(localStorage.getItem("activeCall") === "true");
  // const audio = new Audio('/ringtone.mp3');
  
  // const handleButtonClick = () => {
  //   audio.play().catch((error) => console.error('Error playing audio:', error));
  // };

  const role = 'publisher'
  const apiKey = 47855731;
  const [publisherData, setPublishedData] = useState();
  
  const { t } = useTranslation();
  const [endCall, setEndCall] = useState(false);

  const createSession = async () => {
    const response = await axios.post(config.url + "chat/vonage/session", { user_id: userId }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    setPublishedData(response.data);
    localStorage.setItem("activeCall", true)
  };

  const endSession = async (sessionId, type) => {
    const response = await axios.post(config.url + "chat/vonage/end", { session_id: sessionId }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    setEndCall(true);
    setPublishedData(false);
    localStorage.setItem("activeCall", false)

    toast("The call has ended")
    
    setTimeout(() => {
        window.location.reload();
    }, 2000);
    
  };

  const makeCall = async () => {
    setButtonDisabled(true);
    // handleButtonClick();
    await createSession();
  }

  return (
    <>
      <div className='pos-rel'>
        <button disabled={buttonDisabled} className="main-btn tb-color f-white howver" onClick={() => makeCall()}>{t("Chat voice or video")} </button>
        <div className="prom-off pos-abs" style={{right:"-50px", top:"-10px"}}> {t("NEW")} </div>
      </div>
      {publisherData && !endCall &&
        <div className="modal-confirmation">
          <div className="modal-video">
            <Vonage onCallEnded={endSession} user={publisherData?.user} role={role} apiKey={apiKey} sessionId={publisherData?.session_id} publisherToken={publisherData?.token} />

          </div>
        </div>
      }
         {/* <AudioPlayer/> */}
    </>
  )
}

export default VonageCall