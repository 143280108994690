/* eslint-disable no-underscore-dangle */
import arrayRemoveItem from '../../helpers/arrayRemoveItem';
import PeerConnection from './peer_connection';
import SubscriberPeerConnectionQueue from './subscriberPeerConnectionQueue';
import createPeerConnectionSDPDefault from './peerConnectionSDP';

const PeerConnectionDefault = PeerConnection();

class SinglePeerConnectionController {
  constructor(session, deps = {}) {
    this.createPeerConnectionSDP = deps.createPeerConnectionSDP || createPeerConnectionSDPDefault;
    this._session = session;
    this.PeerConnection = deps.PeerConnection || PeerConnectionDefault;
    this.init();
  }

  init() {
    this.reset();
  }

  reset() {
    this._originalSendMessages = {};
    this._tracks = [];
    this._currentAndPreviousMids = [];
    this._subscriberPcs = {};
    this._peerConnectionSDP = this.createPeerConnectionSDP();
    this._subscriberQueue = new SubscriberPeerConnectionQueue();
    this._sendMessage = () => {};
    if (this._peerConnection) {
      this._peerConnection.disconnect();
      this._peerConnection = null;
    }
  }

  parseOptions(options) {
    return {
      ...options,
      // We always remove unused codecs after iceRestart in SPC
      removeUnusedCodecs: true,
      sendMessage: (type, _payload) => {
        const payload = {
          ..._payload,
        };

        if (payload.sdp) {
          const partialSdp = this._peerConnectionSDP.getPartialSdp(type, payload.sdp);
          payload.sdp = partialSdp;
        }

        this._sendMessage(type, payload);
        if (type === 'answer') {
          // We only send an answer when Mantis previously sent an offer, i.e. we added a new
          // subscriber. Therefore at this point we can consider the negotiation done.
          this.subscriberComplete();
        }
      },
    };
  }

  addListeners() {
    if (!this._peerConnection) {
      return;
    }

    // override the trackAdded listener so we can route the tracks to the proper subscriber.
    this._peerConnection.on('trackAdded', (stream) => {
      const { track, transceiver } = stream;
      if (this._currentAndPreviousMids.includes(transceiver.mid)) {
        return;
      }
      // We store all tracks per Subscriber.
      this._subscriberPcs[this._subscriberPcToAdd._id].push(track.id);
      // Also store all tracks per SinglePeerConnection
      this._tracks.push(track.id);
      this._currentAndPreviousMids.push(transceiver.mid);
      this._subscriberPcToAdd._onTrackAdded(stream);
    });

    this._peerConnection.on('negotiationNeeded', () => {
      this.removeFromSinglePeerConnection(this._subscriberPcToRemove);
    });
  }

  // Singleton to make sure we are using only one PC when SPC. If SPC, we will add Subscriber
  // specific options to handle analytics and negotiation per Subscriber. This will take care
  // to send the answer back to Rumor by its respective Subcriber and not multiple answers.
  // It will instantiate a new regular PC for all other cases.
  getPeerConnection(opt, subscriberPc) {
    this._subscriberPcToAdd = subscriberPc;
    this._sendMessage = opt.sendMessage;
    this._originalSendMessages[subscriberPc._id] = this._sendMessage;

    if (this._peerConnection) {
      this._peerConnection.addOptions(opt);
    } else {
      const parsedOptions = this.parseOptions(opt);
      this._peerConnection = new this.PeerConnection(parsedOptions);

      // Add listeners, which will take care all the tracks for all the subs and if any
      // negotiation needed.
      this.addListeners();
    }

    this._subscriberPcs[subscriberPc._id] = [];

    return this._peerConnection;
  }

  removeMessageSender(subscriberPcId) {
    delete this._originalSendMessages[subscriberPcId];
    const originalSenders = Object.values(this._originalSendMessages);
    this._sendMessage = originalSenders[originalSenders.length - 1] || function () {};
  }

  removeSubscriber(subscriberPcId, transceivers) {
    this._subscriberQueue.enqueue(() => {
      this._subscriberPcToRemove = subscriberPcId;
      this.removeMessageSender(subscriberPcId);
      this._peerConnection.stopTransceivers(transceivers);
    });
  }

  generateOfferWithIceRestart() {
    this._peerConnection.iceRestart();
    this._peerConnection.generateOfferAndSend();
  }

  generateOffer() {
    const createAnswer = () => {
      const answer = this._peerConnectionSDP.createSdp();
      this._peerConnection.processMessage('answer', { content: { sdp: answer } });
      // Subscriber has been finally removed, thus we continue with the next action.
      this._subscriberQueue.dequeueAndProcessNext();
    };
    this._peerConnection.generateOfferAndAnswer(createAnswer);
  }

  removeFromSinglePeerConnection(subscriberPcId) {
    delete this._subscriberPcs[subscriberPcId];

    if (!Object.keys(this._subscriberPcs).length) {
      // Reset all components since we have no subscriber left. Then return,
      // nothing else to do here.
      this.reset();
      return;
    }

    const iceRestartNeeded = this._peerConnectionSDP.isHead(subscriberPcId);
    this._peerConnectionSDP.removeSubscriberMids(subscriberPcId);

    if (iceRestartNeeded) {
      // We process iceRestart at the end of the negotiation
      this._subscriberQueue.finally(() => this.generateOfferWithIceRestart());
    }
    // We make sure we don't create 2 offers without setting an answer.
    this.generateOffer();
  }

  removeTrack(track, subscriberPcId) {
    const trackId = track?.id;
    if (trackId) {
      arrayRemoveItem(this._tracks, trackId);
      arrayRemoveItem(this._subscriberPcs[subscriberPcId] || [], trackId);
    }
  }

  destroy() {
    this.reset();
    this._session = null;
  }

  addSubscriber(subscriberCreate) {
    this._subscriberQueue.enqueue(subscriberCreate);
  }

  addSubscriberMid(mid, subscriberPcId) {
    this._peerConnectionSDP.addSubscriberMid(mid, subscriberPcId);
  }

  subscriberComplete() {
    this._subscriberQueue.dequeueAndProcessNext();
  }

  processAnswer(sdp) {
    return this._peerConnectionSDP.processAnswer(sdp);
  }

  processOffer(sdp) {
    return this._peerConnectionSDP.processOffer(sdp);
  }
}

export default SinglePeerConnectionController;
