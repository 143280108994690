// Vonage.js
import React, { useEffect, useRef, useState } from 'react';
import OT from '@opentok/client';
import './Vonage.css';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { MdFullscreen } from "react-icons/md";
import Chat from '../../Components/Chat/Chat';
import { FaMicrophone, FaMicrophoneAltSlash } from "react-icons/fa";

const Vonage = ({user, role, apiKey, sessionId, publisherToken, onCallEnded}) => {

  const userId = user?.id;
  // console.log(user, 'vezi user')
// console.log(userId);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // console.log(role, apiKey, sessionId, publisherToken)

  const [isCalling, setIsCalling] = useState(true);
  const [isCallEnded, setIsCallEnded] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [session, setSession] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [publisher, setPublisher] = useState(null);
  const subscriberRef = useRef(null);
  
  const [countdown, setCountdown] = useState(120);

  const timeoutIdRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);

  
  const audioRef = useRef(new Audio('/ringtone.mp3'));

  const handleTogglePlay = (isPlaying) => {

      if (!isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setAudioIsPlaying(false);
      } else {
      audioRef.current.play().catch((error) => console.error('Error playing audio:', error));
      setAudioIsPlaying(true);
      }

  };

  const toggleFullScreen = () => {
    const element = document.getElementById('fullscreen-container');

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Are you sure you want to leave? Your call will be disconnected.';
      const shouldEndCall = window.confirm(message);
      // console.log(shouldEndCall);
  
      if (shouldEndCall) {
        localStorage.setItem("activeCall", false);
        handleEndCall();
      }
  
      // console.log(message);
      event.returnValue = message;
      return message;
    };
  
    const handleUnload = () => {
      localStorage.setItem("activeCall", false);
      handleEndCall();
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, []);
  

  useEffect(() => {
    const initializeSession = () => {
      const newSession = OT.initSession(apiKey, sessionId);
      let subscriber;

      newSession.on({
        sessionConnected: () => {
          // console.log('Session connected');
          setIsConnected(true);
          clearTimeout(timeoutIdRef.current);

          if (!publisher) {
            const newPublisher = OT.initPublisher('publisher', {
              insertMode: 'append',
              width: '120px',
              height: '90px'
            });

            setPublisher(newPublisher);

            newSession.publish(newPublisher, { publishAudio: true, publishVideo: true }, (error) => {
              if (error) {
                console.log('Error publishing:', error.message);
              }
            });
          }
        },
        sessionDisconnected: () => {
          // console.log('Session disconnected');
          setIsConnected(false);

          if (subscriberRef.current) {
            subscriberRef.current.destroy();
            subscriberRef.current = null;
          }
        },
        streamCreated: (event) => {
          subscriber = newSession.subscribe(event.stream, 'subscriber', {
            insertMode: 'replace',
            width: '100%',
            height: '100%',
            subscribeToAudio: true,
            subscribeToVideo: true,
          });
          subscriberRef.current = subscriber;
          setIsCallEnded(false);
          setIsCalling(false);
          handleTogglePlay(false);
        },
        streamDestroyed: (event) => {
          // console.log('Stream destroyed:', event.stream);

          if (subscriberRef.current && event.stream.streamId === subscriberRef.current.stream.streamId) {
            // console.log('Subscriber disconnected');
            subscriberRef.current = null;

            if (session) {
              session.unsubscribe(subscriberRef.current);
            }
            if (publisher) {
              publisher.publishVideo(false);
            }
          
            if (subscriberRef.current) {
              subscriberRef.current.subscribeToVideo(false);
            }
            if (subscriberRef.current) {
              subscriberRef.current.destroy();
            }
            onCallEnded(sessionId, 'forced');
            setIsCallEnded(true);
          }

    

  
          // Cleanup session and resources when stream is destroyed
          if (session) {
            session.disconnect();
          }

          if (publisher) {
            publisher.destroy();
          }

          // if (subscriber) {
          //   subscriber.destroy();
          // }

         
        },
      });

      const timeoutId = setTimeout(() => {
        // console.log('Participant not connected within 30 seconds. Destroying session.');
        newSession.disconnect();
      }, 120000);

      timeoutIdRef.current = timeoutId;

      if (session && publisher) {
        session.disconnect();
        publisher.destroy();
      }

      newSession.connect(publisherToken, (error) => {
        if (error) {
          console.log('Error connecting:', error.message);
        }
      });

      setSession(newSession);

      return () => {
        newSession.disconnect();
        if (publisher) {
          publisher.destroy();
        }
        if (subscriber) {
          subscriber.destroy();
        }
      };
    };

    initializeSession();
  }, [apiKey, sessionId, publisherToken, role]);

  const handleEndCall = () => {

    if (publisher) {
      publisher.publishVideo(false);
    }
  
    if (subscriberRef.current) {
      subscriberRef.current.subscribeToVideo(false);
    }
    if (subscriberRef.current) {
      subscriberRef.current.destroy();
    }
  
    if (session) {
      session.disconnect();
    }
    setIsCalling(false);
    handleTogglePlay(false);
    setCallEnded(true);
    onCallEnded(sessionId);
  };

  const [audioIsPlaying, setAudioIsPlaying] = useState(false);

  useEffect(() => {
    let countdownInterval;

    if (isCalling) {

      if(!audioIsPlaying){
        handleTogglePlay(true);
      }
  

      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            handleEndCall();
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
      clearTimeout(timeoutIdRef.current);
    };
  }, [isCalling]);

  const [isMuted, setIsMuted] = useState(true);

  const handleMuteToggle = () => {
    try {
      if (publisher) {
        // Toggle mute state
        setIsMuted((prevMuteState) => {
          const newMuteState = !prevMuteState;
          console.log("New Mute State:", newMuteState);
  
          // Mute or unmute the publisher audio
          publisher.publishAudio(newMuteState);
          console.log("Audio Published with Mute State:", newMuteState);
  
          return newMuteState;
        });
      } else {
        console.error("Publisher not available.");
      }
    } catch (error) {
      console.error("Error during mute toggle:", error);
    }
  };
  
  return (
    <>
    <div className='container-video-message'>
      <div>
    <div className="video-container" id="fullscreen-container">

      {isCallEnded && (
        <div className="call-ended">Call Ended </div>
      )}
      
        <>
        {}
          {role === 'publisher' && isCalling && !subscriberRef.current && (
            <div className="calling-message animated">
                      <div className="profile-details">
              <img
                src={user?.picture}
                className="profile-photo img-background"
                alt="account-img"
              />
            </div>
              You are calling, {user?.username}... 
              {/* ({countdown}s remaining) */}
              <div className='text-center mg-top-10'>
              <button className='main-btn tb-color p-color howver pad-25 vonage-button' onClick={handleEndCall}>End Call</button>
              </div>
            </div>
          )} 
{ !isCallEnded  && 
            <>
          <div id="publisher" className="video"></div>
          <div id="subscriber" className="video"></div>

          </>
}
{ callEnded && 
  <>          <div className="profile-details">
  <img
    src={user?.picture}
    className="profile-photo img-background"
    alt="account-img"
  />
</div>
<div>The video call has ended</div></>
  
}
        </>
 
      {/* <div className="user-name">{user?.username}</div> */}


    </div>
    <div className="bottom-container">
    {!callEnded && !isCalling && !isCallEnded && (
            <>
              <button className='main-btn tb-color p-color pad-25 vonage-button' onClick={handleEndCall}>End Call</button>
              <button className='main-btn vonage-button' onClick={toggleFullScreen}>
              <MdFullscreen />
      </button>
              <button  className="main-btn pad-25 vonage-button" onClick={handleMuteToggle}>
                {isMuted ? <>Mute <FaMicrophone/></> : <>Unmute <FaMicrophoneAltSlash/></> }
              </button>
            </>
          )}

    </div>
     
              </div>
    <div> 
    {!isCalling && <Chat receiver_id={userId} />}
 </div>
 </div>
    </>
  );
};

export default Vonage;

{/* <div className="video-container">


{isConnected && (
  <>
    <div className="video-topbar">
      <div className="video-username">Rox kirokv</div>
    <div>   <button className="main-btn tb-color f-white howver" onClick={handleEndCall}>End Call</button></div>
    </div>
  
    <div id="publisher" className="video"></div>
    <div id="subscriber" className="video">       
    {isCalling && !subscriberRef.current && (
      <div className="container-inside-message animated move">  You are calling, Jhon... ({countdown}s remaining)
      </div>
    )} 

{isCallEnded && (<>
  <div className="container-inside-message">The video session has ended!</div>
</>)
}
    
    
    </div>


  
  </>
)}

</div> */}