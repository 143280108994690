import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../config";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function ResetPassword() {
    const { t, i18n, ready } = useTranslation();
    const { token } = useParams();
    const [errorMessage, setErrorMessage] = useState();

    const [isPassword, setIsPassword] = useState(false);
    const [isConfirmPassword, setIsConfirmPassword] = useState(false);

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [message, setMessage] = useState(false);

    function changePassword(password, confirmPassword) {
        const resetPassword = async () => {
            return await axios.post(config.url + "users/reset-password", {
                token: token,
                password: password,
                password_confirmation: confirmPassword,
            });
        };

        resetPassword()
            .then((result) => {
                if (result.status === 200) {
                    toast(t("Password changed successfully"));
                    setMessage(
                        "Your password has been successfully changed. You can now securely access your account using your new password."
                    );
                }
            })
            .catch((error) => {
                toast(
                    t("It seems there was an issue with changing your password")
                );
            });
    }

    function postForm(e) {
        e.preventDefault();
        setIsPassword(false);
        setIsConfirmPassword(false);
        setErrorMessage(false);
        if (password === undefined) {
            setIsPassword("Password is required");
        } else if (confirmPassword === undefined) {
            setConfirmPassword("Password is required");
        } else if (password != confirmPassword) {
            setErrorMessage("Passwords do not match");
        } else {
            changePassword(password, confirmPassword);
        }
    }

    return (
        <>
            <Breadcrumb
                pageTitle={t("Reset Password")}
                text={t(
                    "Thank you for your cooperation in keeping your account secure. Let's get started on resetting your password."
                )}
            />

            <div className="login-section padding-tb">
                <div className="container">
                    <div className="login-form bb-color">
                        {message && <p>{t(message)}</p>}
                        {!message && (
                            <>
                                <h3>
                                    <strong>{t("Reset Password")}</strong>
                                </h3>
                                <form
                                    style={{
                                        textAlign: "center",
                                        marginTop: "20px",
                                    }}
                                    onSubmit={(e) => postForm(e)}
                                >
                                    <div className="form-login-item">
                                        <input
                                            className={
                                                "w-100" +
                                                (isPassword ? " red-error" : "")
                                            }
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            placeholder={t("Password")}
                                        />
                                    </div>
                                    {isPassword && (
                                        <div className="error-message">
                                            {isPassword}
                                        </div>
                                    )}
                                    <div className="form-login-item">
                                        <input
                                            className={
                                                "w-100" +
                                                (isConfirmPassword
                                                    ? " red-error"
                                                    : "")
                                            }
                                            type="password"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) => {
                                                setConfirmPassword(
                                                    e.target.value
                                                );
                                            }}
                                            placeholder={t("Password confirm")}
                                        />
                                    </div>
                                    {isConfirmPassword && (
                                        <div className="error-message">
                                            {isConfirmPassword}
                                        </div>
                                    )}

                                    {errorMessage && (
                                        <div className="error-message">
                                            {errorMessage}
                                        </div>
                                    )}
                                    <button className="form-button tb-color f-white howver">
                                        {t("Submit now")}
                                    </button>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
