import React, { useState } from "react";

import axios from "axios";
import config from "../config";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function ForgotPassword() {
    const { t, i18n, ready } = useTranslation();

    const [errorMessage, setErrorMessage] = useState();

    const [isEmail, setIsEmail] = useState(false);
    const [email, setEmail] = useState();

    const [message,setMessage] = useState(false);

    function sendEmail(email) {
      const forgotPassword = async () => {
          return await axios.post(config.url + "users/forgot-password", {
              email: email,
          });
      };

      forgotPassword()
          .then((result) => {
              if (result.status === 200) {
                  setMessage('In the email, you will find a password reset link. Click on the link to be directed to the password reset page.');
                  toast(t("Email Sent Successfully"));

              }
          })
          .catch((error) => {
              toast(
                  t("It seems there was an issue with changing your password")
              );
          });
  }

    function postForm(e) {
        e.preventDefault();
        setIsEmail(false);

        if (email === undefined) {
            setIsEmail("Email is required");
        }
        else{
          sendEmail(email);
        }

    }

    return (
        <>
            <Breadcrumb
                pageTitle={t("Forgot Password")}
                text={t(
                    "Enter your email address below and we'll send you instructions on how to reset your password"
                )}
            />

            <div className="login-section padding-tb">
                <div className="container">
                    <div className="login-form bb-color">
                      {message && <p>{t(message)}</p>}
                      {!message && <>
                        <h3>
                            <strong>{t("Forgot Password")}</strong>
                        </h3>
                        <form
                            style={{ textAlign: "center", marginTop: "20px" }}
                            onSubmit={(e) => postForm(e)}
                        >
                            <div className="form-login-item">
                                <input
                                    className={
                                        "w-100" + (isEmail ? " red-error" : "")
                                    }
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    placeholder={t("Email")}
                                />
                            </div>
                            {isEmail && (
                                <div className="error-message">{isEmail}</div>
                            )}

                            {errorMessage && (
                                <div className="error-message">
                                    {errorMessage}
                                </div>
                            )}
                            <button className="form-button tb-color f-white howver">
                                {t("Submit now")}
                            </button>
                        </form>
                        </>}
                    </div>
                </div>
            </div>
        </>
    );
}
