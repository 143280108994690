import React,{useState,useEffect}from "react";
import Select from "react-select";

function SelectSearch(props) {

  const [selectedOption,setSelectedOption] = useState();
  const onChangeValue = (e) => {

    setSelectedOption(e);
    
    props.parrentInputValue(e.value);
  };

  useEffect(() => {
    console.log(props.selectedOption);
    setSelectedOption(props.selectedOption);
  },[])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px solid var(--main-color)",
      color: "white",
      // padding: 7,
      background: state.isSelected ? "var(--main-link-button-color)" : "var(--main-lighter-color)",
      "&:hover": {
        background: "var(--main-link-button-color)",
      },
    }),
    control: () => ({
      backgroundColor: "var(--main-lighter-color)",
      display: "flex",
      border:
        props?.inputBorderNone == "false"
          ? "none"
          : "1px solid rgba(255, 255, 255, 0.1)",
      borderColor: props.error == "red-error" ? "red" : "",
      borderRadius: '100px',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
      bordeRadius: '100px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };
  return (
    <Select
      inputProps={{autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off' }}
      name={props.name}
      id={props.id}
      onChange={onChangeValue}
      styles={customStyles}
      options={props.options}
      placeholder={props.placeholder}
      value={selectedOption}
      
    />
  );
}

export default SelectSearch;
