import React from "react";
import "./Login.css";
import SignupForm from "../Components/Forms/SignupForm/SignupForm";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";

function Signup() {
    const { t, i18n, ready } = useTranslation();
    return (
        <>
            <Breadcrumb
                pageTitle={t(process.env.REACT_APP_APP_NAME + " Registation")}
                text={t(
                    "Don't have an account yet? What are you thinking, fill the fileds and start meeting new people!"
                )}
            />
            <SignupForm />
        </>
    );
}

export default Signup;
