import React, { useState, useEffect } from "react";
import {
  FaGrinWink,
  FaVenusMars,
  FaRegBuilding,
  FaBirthdayCake,
  FaCircle,
} from "react-icons/fa";
import { BsGlobe } from "react-icons/bs";
import { AiFillHeart, AiFillLock } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import Modal from "../Modal/Modal";
import axios from "axios";
import config from "../../config";
import {useTranslation} from 'react-i18next';
import SelectSearcher from "../SelectSearch/SelectSearch";
import { rangeNumber } from "../../Utils/Utils";
import { toast } from "react-toastify";

function UserDetails(props) {

  let allAges = [];
  rangeNumber(18,99).forEach(element => {
    allAges.push({ label: element,value: element});
  });
  const [user, setUser] = useState();
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityId, setCityId] = useState();
  const [country, setCountry] = useState();
  const [defaultCountry, setDefaultCountry] = useState();
  const [formData, setFormData] = useState();
  const [displayModal, setDisplayModal] = useState(false);

  const { t, i18n, ready } = useTranslation();

  function handleFields(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const saveChanges = async (e) => {
    e.preventDefault();
    setDisplayModal(true);
  };

  const setStateModal = async (childrenData) => {
    if (childrenData === true || childrenData === false) {
      setDisplayModal(false);
    }

    if (childrenData === true) {
      
      const body = {
        // ...formData,
        civil_status: formData.civil_status,
        looking_for: formData.looking_for,
        password: formData.password,
        password_confirmation: formData.password_confirm,
      }

      

      const response = await axios.put(config.url + "users/details", body, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if(response?.status === 200){
        toast(t("Your changes have been saved successfully"));
        setTimeout(() => {
          window.location.reload();
        }
        , 2000);
      }
      else{
        toast(t("There was an error, please try again"));
      }
    }
  };

  useEffect(() => {
    setUser(props.user);
    setFormData({
      age: props.user?.age ? props.user.age : "",
      gender: props.user?.gender ? props.user.gender : "",
      country: props.user?.country ? props.user.country : "",
      city: props.user?.city ? props.user.city : "",
      looking_for: props.user?.looking_for ? props.user.looking_for : "",
      civil_status: props.user?.civil_status ? props.user.civil_status : "",
    });
  }, [props.user]);

  let fieldsArray = [
    {
      title: t("Age"),
      name: "age",
      inputType: "select",
      icon: <FaBirthdayCake />,
      text: user?.age ? user.age + " years" : "",
      options: allAges,
      normalDisplay: true,
      disabled: true
    },
    {
      title: t("Gender"),
      name: "gender",
      inputType: "text",
      icon: <FaVenusMars />,
      text: user?.gender ? user.gender : "",
      options: config.allGenders,
      normalDisplay: true,
      disabled: true
    },
    {
      title: t("Country"),
      name: "country",
      inputType: "text",
      icon: <BsGlobe />,
      text: user?.country ? user.country : "",
      options: countryList,
      normalDisplay: true,
      disabled: true
    },
    {
      title: t("City"),
      name: "city",
      inputType: "text",
      icon: <FaRegBuilding />,
      text: user?.city ? user.city : "",
      options: cityList,
      normalDisplay: true,
      disabled: true
    },
    {
      title: t("Looking for"),
      name: "looking_for",
      inputType: "select",
      icon: <AiOutlineSearch />,
      text: user?.looking_for ? user.looking_for : "",
      options: config.allGenders,
      normalDisplay: true
    },
    {
      title: t("Civil status"),
      name: "civil_status",
      inputType: "select",
      icon: <AiFillHeart />,
      text: user?.civil_status ? user.civil_status : "",
      options: config.allCivilsStatus,
      normalDisplay: true
    }
  ];

  if(props.isForm){
    fieldsArray.push(  {
      title: t("Password"),
      name: "password",
      inputType: "password",
      icon: <AiFillLock />,
      text: "",
      placeholder: t("Password"),
      normalDisplay: false
    },
    {
      title: t("Password confirm"),
      name: "password_confirm",
      inputType: "password",
      icon: <AiFillLock />,
      text: "",
      placeholder: t("Password confirm"),
      normalDisplay: false
    })
  }

  const handleCountry = async (childrenData) => {
    setCountry(childrenData);
    const response = await axios.get(
      config.url + "countries/code/" + childrenData + "/cities"
    );
    let countryCities = [];
    if (response.data.cities) {
      countryCities = response.data.cities.map((city) => {
        return { value: city.id, label: city.name }; 
      });
    }

    setCityList(countryCities);
  };

  useEffect(() => {
    getCountries();
  }, [countryList]);

  const getCountries = async () => {
    if (countryList.length === 0) {
      const response = await axios.get(config.url + "countries");
      response.data.countries.map((country) => {
        countryList.push({
          id: country.id,
          value: country.code,
          label: country.name,
        });
      });
    }
  };
  const displayFields = fieldsArray.map((field, index) => (
    <div key={index + field.name} className="item">
      <div className="item-block ">
        <span> {field.title}</span>
        <span className={"item vb-color" + (field.disabled && props?.isForm ? ' input-disabled' : '')}>
          {field.icon}
          {(() => {
            const name = field?.name;
            const is_normal_form = props?.isForm;

            if (is_normal_form) {
              if(field.disabled){
                return <span>{field.text}</span>
              }
              if (field.inputType === "text" || field.inputType === "password") {
                return (
                  <span>
                  <input
                    className="main-input pad-0 w-100 b-none mb-0"
                    id={field.name}
                    autoComplete="chrome-off"
                    name={field.name}
                    placeholder={field?.placeholder ? field.placeholder : ""}
                    type={field.inputType}
                    value={formData?.[name] ? formData[name] : ""}
                    onChange={handleFields}
                  ></input>
                  </span>
                );
              } else if (field.inputType === "select") {
                return (
                  <span className="w-100">
                  <select
                    className="main-select w-100 pad-0 b-none mb-0"
                    id={field.name}
                    name={field.name}
                    onChange={handleFields}
                    value={formData?.[field.name]}
                  >
                    {field.options.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  </span>
                  
                );
              } else if (field.inputType === "select2") {
                return (
                  <div className="w-95">
                    <SelectSearcher
                      parrentInputValue={
                        field.name == "country"
                          ? handleCountry
                          : (childrenData) =>
                              setFormData((prevState) => ({
                                ...prevState,
                                [field.name]: childrenData,
                              }))
                      }
                      options={field.options}
                      selectedOption={field.selectedValue}
                      name={field.name}
                      id={field.name}
                      error=""
                      inputBorderNone="false"
                      placeholder={t("Select country")}
                    />
                  </div>
                );
              } else {
                return field.text;
              }
            }
            else{
              return(
                <>
               <span className="item vb-color">{field.text}</span>
               
              </>
              )

            }
          })()}
        </span>
      </div>
    </div>
  ));

  return (
    <div className="user-details section-details bb-color main-shadow-box">
      {!props.isForm && <h3>{t("Profile details")}</h3>}
      {props.isForm ? (
        <form className="user-details" onSubmit={(e) => saveChanges(e)}>
          {displayFields}
          <div className="item flex-end">
            <div className="item-block mg-top-30px w-100">
              <button className="main-btn tb-color f-white w-100 howver ">
                {t("Save changes")}
              </button>
            </div>
          </div>
        </form>
      ) : (
        displayFields
      )}
      {displayModal && (
        <Modal
          text={t("Are you sure you want to update the informations?")}
          confirm={setStateModal}
        />
      )}
    </div>
  );
}

export default UserDetails;
